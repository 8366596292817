@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
  /* --gray: #f4f4f4; */
  --grayBorder: #a9a9a9;
  --darkBlue: #00008b;
  font-family: 'Lato', sans-serif;
}

body {
  /* font-family: 'Inter', sans-serif; */
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  margin: auto;
  /* background-color: #FFF; */
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

.main_header {
  /* background: #f9f8f7; */
  /* box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08); */
  position: relative;
}

.main_header--wrap {
  max-width: 100%;
  height: 6rem;
  display: flex;
  padding: 0 0.5rem;
  align-items: center;
  margin: 0 auto;
}

.main_header--navbar {
  width: 100%;
}

.main_header--inner {
  padding: 56px 50px 45px;
}

.button1 {
  font-weight: 600 !important;
}

.loginScren {
  display: flex;
  height: calc(100vh - 125px);
}

.loginScrenInner {
  margin: auto;
  text-align: center;
  padding: 30px;
}

.loginScrenButtons {
  margin: 75px auto;
  max-width: 400px;
}

.loginScrenButtonLogin {
  background: #39585c;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto 15px;
  padding: 20px;
  width: 100%;
}

.loginScrenButtonSignup {
  background: #659f8f;
  border: 0;
  border-radius: 10px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.Dashboard {
  background: #f9f8f7;
  height: calc(100vh - 145px);
}

.Dashboard--wrap {
  max-width: 1440px;
  margin: 0 auto;
}

.Dashboard--grid {
  display: grid;
  grid-template-columns: 250px 1fr;
  /* place-items: center; */
  grid-gap: 0;
  height: 100%;
}

.Dashboard--item.Dashboard--item--sidebar {
  box-shadow: 0px 4px 20px rgb(0 0 0 / 8%);
}

.Dashboard--item.Dashboard--item--sidebar a:hover,
.Dashboard--item.Dashboard--item--sidebar a {
  color: #000;
}

.Dashboard--item.Dashboard--item--content {
  padding: 68px 101px 68px 53px;
}

.shadowBoxStandard {
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}

.DashboardTitle {
  display: flex;
  padding: 15px 29px 18px 38px;
  margin: auto auto 57px 0;
  width: fit-content;
  background: #f9f8f7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
}

.DashboardTitle--txt {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  margin: auto;
}

.DashboardTitle--txt svg {
  width: 32px;
  vertical-align: middle;
}

.DashboardTitleMain {
  display: flex;
}

.DashboardFrom {
  background: #f9f8f7;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.08);
  border-radius: 13px;
  padding: 31px 53px 45px 38px;
  margin: 0 auto 67px;
}

.DashboardFrom h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #212529;
  margin-right: 30px;
}

.DashboardVestmapTitle h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 20px;
  color: #212529;
  margin-right: 30px;
  text-align: left;
  margin: auto;
}

.DashboardVestmaps {
  margin: 0 auto 50px;
}

.DashboardVestmapsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 30px;
  grid-row-gap: 3rem;
  align-items: stretch;
}

.DashboardVestmapsGrid_Loading {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
  align-items: center;
}

.DashboardVestmapsFlexBox {
  display: flex;
  direction: column;
  background-color: #ffffff;
  padding: 15px;
  box-shadow: 0 2px 2px 3px #c2c2c2;
  border-radius: 10px;
  height: 100%;
}

.DashboardVestmapsImageBox {
  margin: auto 0;
  max-width: 300px;
}

.DashboardVestmapsImageBox img {
  display: block;
  border-radius: 10px;
}

.DashboardVestmapsContentBox h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 32px;
  color: #171717;
  text-align: left;
  /* margin: 0 auto auto 0; */
  width: 100%;
  flex-grow: 1;
  display: flex;
  align-items: center;
}

.DashboardVestmapsContentBox button {
  height: 55px;
  margin: auto auto 0 0;
}

.DashboardVestmapsFlexBox {
  display: grid;
  grid-template-columns: 215px 1fr;
  grid-gap: 23px;
  align-items: center;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 0px 1px rgba(13, 13, 13, 0.9), 0px 1px 3px #0d0d0d;
  border-radius: 16px;
  min-height: 163px;
}

.DashboardVestmapsImageBox img {
  border-radius: 10px;
  display: block;
  width: 100%;
  height: auto;
}

.DashboardVestmapsImageBox {
  position: relative;
}

.DashboardVestmapsImageBox .DashboardVestmapsImageLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 64px;
  /* z-index: 9999; */
  transform: translate(-50%, -50%);
}

.DashboardVestmapsContentBoxProcess img {
  width: 24px;
  display: block;
  margin: auto 0;
  margin-right: 15px;
}

.DashboardVestmapsContentBoxProcess {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  color: #c5c5c5;
}

.DashboardVestmapsContentBoxTitle1,
.DashboardVestmapsContentBoxTitle2 {
  background: #eee;
  width: 100%;
  height: 20px;
  margin: 0 auto 15px;
}

.DashboardVestmapsContentBoxTitle2 {
  background: #f6f6f6;
  width: 50%;
  margin-left: 0;
}

.DashboardVestmapsContentBoxTitle {
  margin: 0 auto auto;
  width: 100%;
}

.DashboardVestmapsContentBox {
  margin: auto auto auto 0;
  /* text-align: center; */
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  height: 100%;
  width: 100%;
}

.hamburger {
  border: 0;
  height: 40px;
  width: 40px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  z-index: 1000;
  position: relative;
  transform: translateX(0);
}

.no-scroll {
  overflow: hidden;
}

.divvy-div {
  /* width: 100%;
  -moz-column-count: 2;
  -moz-column-width: 50%;
  -webkit-column-count: 2;
  -webkit-column-width: 50%;
  column-count: 2;
  column-width: 50%; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.pagination {
  list-style: none;
  padding: 0;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.pagination li button:hover,
.pagination li button {
  width: 32px;
  height: 32px;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  line-height: 32px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: block;
  text-align: center;
}

.pagination li button:hover {
  background: #eee;
}

#vestmap-card-content::-webkit-scrollbar {
  display: none;
}

@media only screen and (max-width: 1640px) {
  .DashboardVestmapsFlexBox {
    grid-template-columns: 150px 1fr;
  }
}

@media only screen and (max-width: 1023px) {
  .DashboardVestmapsGrid {
    grid-template-columns: 1fr 1fr;
  }

  .DashboardVestmapsFlexBox {
    grid-template-columns: 200px 1fr;
  }
}

@media only screen and (max-width: 1340px) {
  .DashboardVestmapsContentBox h3 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 1240px) {
  /* .hamburger {
    display: flex;
  } */

  .main_header--navbar {
    display: none;
  }
}

@media only screen and (max-width: 1140px) {
  .DashboardVestmapsFlexBox {
    grid-template-columns: 150px 1fr;
  }

  .DashboardTitleMain {
    display: block;
    margin: 0 auto 57px;
  }
}

@media only screen and (max-width: 1024px) {
  .Dashboard--grid .Dashboard--item.Dashboard--item--content {
    padding: 60px 30px;
  }

  .DashboardVestmapsContentBox h3 {
    margin: 0 auto 15px 0;
  }

  .Dashboard--grid {
    grid-template-columns: 1fr;
  }

  .Dashboard--grid .Dashboard--item.Dashboard--item--sidebar {
    display: none;
  }

  .Dashboard--grid .Dashboard--item.Dashboard--item--content {
    padding: 68px;
  }
}

@media only screen and (max-width: 899px) {
  .loginScrenCopyright {
    font-size: 16px;
  }

  .DashboardVestmapsContentBox,
  .DashboardTitle {
    flex-wrap: wrap;
  }

  .DashboardTitle--txt {
    margin: 0 0 10px;
  }

  .DashboardTitle button {
    margin: 0;
  }

  .DashboardVestmapsFlexBox {
    grid-template-columns: 1fr;
  }

  .DashboardVestmapsImageBox {
    max-width: 100%;
    margin: auto;
  }
}

@media only screen and (max-width: 640px) {
  .loginScrenCopyright {
    font-size: 12px;
  }

  .Dashboard--grid .Dashboard--item.Dashboard--item--content {
    padding: 30px 15px;
  }

  .DashboardVestmapsGrid {
    grid-template-columns: 1fr;
  }
}
