.errorPopUp {
  position: fixed;
  /* top: 50%;
  left: 50%; */
  /* transform: translate(-50%, -50%); */
  background-color: white;

  border-radius: 10px;
  width: 25%;
  border: 0.5px solid black;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  z-index: 1000;
  text-align: center;
  padding: 0px;
}

.bgArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0, 0, 0, 0.6); */
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.errorPopUp {
  animation: fadeIn 0.5s ease-out forwards;
}
