/* Styles for .bigblock
.IncomeStyles .bigblock {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--gray);
    padding: 3vh 3vw;
    border-radius: 25px;
    margin: 2vh 0;
    flex-direction: column;
}

.IncomeStyles .bigblock .leftblock {
    width: 100%;
}

.IncomeStyles .bigblock .leftblock h5 {
    font-weight: 600;
    font-size: 1.1rem;
}

.IncomeStyles .bigblock .legend {
    margin: 1vh 0;
}

.IncomeStyles .bigblock .nomt {
    margin-top: 0;
}

.IncomeStyles .sixblock {
    margin: 2vh 0;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.IncomeStyles .sixblock .sixth {
    padding: 3vh 2vw;
    width: 48%;
    text-align: center;
    background-color: var(--gray);
    margin: 0.5vh 0;
    border-radius: 20px;
}

.IncomeStyles .sixblock .sixth span {
    font-weight: 600;
    margin: 1vh 0;
}

.IncomeStyles .homeVal {
    text-align: center;
}

.IncomeStyles .homeVal .title {
    font-size: 1.1rem;
    font-weight: 600;
    width: 60%;
    padding: 1vh 2vw;
    margin: 1vh auto;
    border-radius: 20px;
}

.IncomeStyles .homeVal .line {
    display: flex;
    justify-content: space-between;
    margin: 1vh 0;
}

.IncomeStyles .homeVal .line .third {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    margin: 1vh 1vw;
    width: 31%;
    text-align: center;
    border-radius: 20px;
}

.IncomeStyles .homeVal .line .third span {
    font-weight: 600;
    margin: 1vh 0;
}

.IncomeStyles .annual {
    text-align: center;
}

.IncomeStyles .annual .title {
    font-size: 1.1rem;
    font-weight: 600;
    width: 90%;
    padding: 1vh 2vw;
    margin: 1vh auto;
    border-radius: 20px;
}

.IncomeStyles .annual .line {
    display: flex;
    justify-content: space-between;
    margin: 1vh 0;
}

.IncomeStyles .annual .line .two {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    margin: 1vh 1%;
    width: 48%;
    text-align: center;
    border-radius: 20px;
}

.IncomeStyles .annual .line .two span {
    font-weight: 600;
    margin: 1vh 0;
}

*/

/* --------------------- */
.sectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.column {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    max-width: 45%;
    padding: .5rem 1rem;
}

.column h2 {
    font-size: 1.2rem;
    font-weight: 600;
    text-align: center;
    padding: .5rem;
    border-bottom: 2px solid var(--grayBorder);
}

.columnHeader {
    width: max-content;
    border-radius: 20px;
    padding: .4rem 1.5rem;
    font-size: 1.3rem;
    font-weight: 700;
    background-color: var(--gray);
}

.column>div {
    text-align: center;
    border-bottom: 2px solid var(--grayBorder);
    width: 100%;
    padding: .5rem;
}

.column>div>h4 {
    font-size: 1rem;
    font-weight: 400;
    margin: .5rem;
}

.column>div>h3 {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

@media screen and (max-width: 768px) {
    .sectionContainer {
        flex-direction: column;
        gap: 1rem;
    }

    .column {
        max-width: 100%;
    }
}