.overlayContent {
  /* background-color: #fff; Adjust as needed */
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  /* Add a subtle box shadow */
  text-decoration: none;
}

.closer {
  display: flex;
  align-items: center;
  justify-content: end;
  cursor: pointer;
}

.closer img {
  height: 30px;
  width: auto;
}

.overlay a {
  color: blue;
  display: block;
  text-align: left;
}

.overlay {
  
  background: white;
  color: black;
  
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  
  text-decoration: none;
  text-align: left;

}

.overlay p {
  text-align: justify;
  text-decoration: none;
  font-weight: 400;
  margin: 0.5rem;
}

.hpiStyle {
  text-align: left;
  text-decoration: none;
  font-size: 1rem;
}
