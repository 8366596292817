.accordionSummary {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  /* borderRadius: '16px',
   */
}

.icon {
  margin-right: 1.5rem;
}

.icon > img {
  width: 2rem;
  height: auto;
}

.text {
  font-weight: 500;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
}

.dialogHeader {
  padding: 1rem;
  background-color: var(--gray);
}

.dialogHeaderChild > h2 {
  display: block;
  font-size: 1.3rem;
  font-weight: 900;
}

@media screen and (max-width: 600px) {
  .dialogHeaderChild > h2 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .dialogHeaderButton {
    position: relative;
  }
}

.dialogContent {
  background-color: var(--gray);
}

.dialogActions {
  position: relative;
  background-color: var(--gray);
  height: 2rem;
}

.dialogActions > img {
  height: auto;
  width: 25rem;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.buttonContainer {
  position: absolute;
  right: 0;
  display: flex;
  gap: 0.75rem;
}

@media screen and (max-width: 600px) {
  .buttonContainer {
    position: relative;
    margin: '.25rem 0 0 0';
  }
}
