.sectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
}

.statsSection {
    width: 100%;
    display: grid;
    grid-template-columns: 20rem 1fr;
    box-sizing: border-box;
}


.statsIcons {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 600px) {
    .statsSection {
        grid-template-columns: 1fr;
    }

    .statsIcons {
        grid-template-columns: 1fr;
    }

}

.mainBlock {
    display: grid;
    grid-template-columns: repeat(2, 20rem);
    /* gap: 1rem; */
    column-gap: 1rem;
}

@media screen and (max-width: 600px) {
    .mainBlock {
        grid-template-columns: repeat(2, 1fr);
    }

}

.singleBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* gap: .25rem; */
    border-radius: 1rem;
    /* border: 1px solid var(--gray); */
    text-align: center;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
    background-color: var(--gray);
    margin: .5rem;
    padding: 1rem;
}

.singleBlock h4 {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    /* margin: 0; */
    /* max-width: 80%; */
}

.singleBlock h3 {
    font-size: 1rem;
    font-weight: 600;
}