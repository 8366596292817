/* Set variable */
:root {
  --gray: #f9f9f9;
}

h2 {
  font-size: 1.4rem;
  font-weight: 700;
  margin: 0;
  text-align: center;
}

h3 {
  font-size: 1.2rem;
  font-weight: 600;
  margin: 0;
  color: var(--dark-blue);
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-items: center;
  align-items: center;
  width: 100%;
  gap: 1.5rem;

  /* font-family: 'Poppins', sans-serif; */
}

.mapContainer {
  width: 100%;
  /* width: 50rem; */
  height: auto;
  background-color: var(--gray);
  box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1),
    0 8px 10px -6px rgb(0 0 0 / 0.1);
}

.fullRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: stretch;
  gap: 0.5rem;
  flex-wrap: wrap;
  margin: 1rem;
  box-sizing: border-box;
}

.grayBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--gray);
  border-radius: 1rem;
  flex-grow: 1;
  /* padding: 0 .5rem; */
}

.paddedGrayBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  background-color: var(--gray);
  border-radius: 1rem;
  flex-grow: 1;
  padding: 2rem 0;
}

.grayBox h4 {
  font-size: 1.1rem;
  font-weight: 500;
  text-align: center;
  margin: 0.5rem;
  /* max-width: 80%; */
}

.grayBox h3 {
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.stats {
  background-color: var(--gray);
  border-radius: 20px;
  padding: 3rem 1rem;
}

.w20 {
  width: 20%;
}

.w15 {
  width: 15%;
}

.w30 {
  width: 30%;
}
