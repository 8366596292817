.confirmationPopUp {
    position: fixed;
    background-color: white;
   
    border-radius: 10px;
    height: 80%;
    width: 80%;
    border: 0.5px solid black;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    padding: 0px;
}

.backgroundArea {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}