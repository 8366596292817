.sectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 1rem;
}

.statsContainer {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 2fr;
    background-color: var(--gray);
    border-radius: 20px;
    padding: 1rem;
    box-sizing: border-box;
}

.statPix {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stat1Legend {
    display: flex;
    align-items: flex-end;
    gap: .5rem;
    background-color: white;
    border-radius: 20px;
    padding: .5rem 1.5rem;
    margin: 1rem 1rem;
    width: max-content;
}

.statsContainer h5 {
    margin: 0;
    font-size: .9rem;
    font-weight: 600;
}

.stat2Legend {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
}


/* h2 {
    font-size: 1.5rem;
    font-weight: 700;
} */

.fullRowModifier {
    margin: .25rem 1rem;
}

.fullRowModifier h4 {
    /* max-width: 5rem; */
    width: 6rem;
    /* margin: 0; */
}

.section2 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    justify-content: center;
    align-items: center;
}


@media screen and (max-width: 600px) {

    .statsContainer {
        grid-template-columns: 1fr;
    }

    .stat2Legend {
        grid-template-columns: 1fr;
    }

    .section2{
        grid-template-columns: 1fr 1fr;
    }
}