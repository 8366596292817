/* .SchoolStyles .threeblocks {
    display: flex;
    justify-content: space-between;
    margin: 2vh 0;
    flex-direction: column;
    text-align: center;
    justify-content: center;
}

.SchoolStyles .threeblocks .blueblock {
    padding: 2vh 2vw;
    width: 100%;
    border: 2px solid var(--blue-light);
    border-radius: 20px;
    margin: 1vh 0;
}

.SchoolStyles .threeblocks .blueblock h4 {
    font-weight: 500;
}

.SchoolStyles .threeblocks .blueblock p {
    margin: 0.5vh 0;
    font-weight: 600;
}

.SchoolStyles .title {
    font-size: 1.1rem;
    font-weight: 600;
    width: 75%;
    padding: 1vh 2vw;
    margin: 1vh auto;
    border-radius: 20px;
    text-align: center;
    color: var(--darkBlue);
}

.SchoolStyles .schools .school {
    text-align: center;
    color: var(--darkBlue);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.SchoolStyles .schools .school h4 {
    text-decoration: underline;
    text-align: center;
    margin: 1vh 0;
    display: block;
}

.SchoolStyles .schools .school .schoolRating {
    background-color: var(--orange);
    border-radius: 15px;
    width: min-content;
}

.SchoolStyles .schools .school .schoolRating p {
    width: auto;
    color: #fff;
    padding: 1.5vh 7vw;
}

@media (min-width: 1024px) {
    .SchoolStyles .threeblocks .line {
        display: flex;
    }

    .SchoolStyles .schools .school {
        cursor: pointer;
        flex-direction: row;
        justify-content: center;
        margin: 2vh 0;
    }

    .SchoolStyles .schools .school .schoolRating {
        margin: 0 1vw;
    }
} */

.sectionContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.heading {
    margin: 2rem auto;
    background-color: var(--gray);
    width: max-content;
    padding: 1rem 3rem;
    border-radius: 5px;
}


.sectionPart>div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin: .5rem auto; */
    padding: .5rem 0;
    /* width: 100%; */
    /* height: 100%; */

}

div.sectionPart>div:nth-child(2) {
    /* background-color: blue; */
    border-top: 1px solid var(--grayBorder);
    border-bottom: 1px solid var(--grayBorder);
}


.sectionPart>div>a {
    display: block;
    text-decoration: none;
    color: white;
    background-color: orangered;
    border-radius: 15px;
    min-width: 3rem;
    padding: .25rem 1rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-shrink: 0; */
    /* flex-grow: 1; */
}

.sectionPart h4 {
    font-size: 1.2rem;
    font-weight: 600;
    /* color: var(--darkBlue); */
    text-decoration: underline;
    /* height: 4rem; */
    display: flex;
    justify-content: center;
    align-items: center;
}