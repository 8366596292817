.sectionContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}


.scale {
    width: 100%;
    padding: .5rem 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.numbers{
    width: 100%;
    margin: .5rem 0;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.gradient {
    width: 100%;
    height: 2rem;
    background: hsla(75, 78%, 29%, 1);
    background: linear-gradient(0deg, hsla(75, 78%, 29%, 1) 0%, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 0%, 1) 100%);
    background: -moz-linear-gradient(0deg, hsla(75, 78%, 29%, 1) 0%, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 0%, 1) 100%);
    background: -webkit-linear-gradient(0deg, hsla(75, 78%, 29%, 1) 0%, hsla(0, 0%, 100%, 1) 50%, hsla(0, 0%, 0%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#668310", endColorstr="#FFFFFF", GradientType=1);
}

.gridder {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
}

@media screen and (max-width: 600px) {
    .gridder {
        grid-template-columns: 1fr;
    }

}


@media screen and (min-width: 600px) and (max-width: 900px) {
    .gridder {
        grid-template-columns: repeat(2, 1fr);
    }

}

.gridderColumn>h4 {
    font-size: 1.3rem;
    margin: 1rem auto;
}


.gridderColumn>div {
    background-color: var(--gray);
    border-radius: 1rem;
    padding: 10px;
    text-align: center;
    margin: 1rem auto;
}

.gridderColumn h3 {
    font-size: 1.1rem;
    line-height: 1.3;
}

.gridderColumn p:not(.hpiStyle) {
    text-align: center;
    font-size: 1.2rem;
}


.group3 {
    width: 100%;
}

.map {
    width: 100%;
    height: 30rem;
    box-sizing: border-box;
    padding: 1rem;
}

@media screen and (max-width: 600px) {
    .map {
        height: 15rem;
        margin-top: 1rem;
        margin-bottom: 1rem;
    }

}


.captionText>p {
    font-size: .75rem;
    line-height: 1.1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.captionText>p>span {
    font-weight: 600;
}