.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
}

.stats {
    background-color: var(--gray);
    border-radius: 20px;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    box-sizing: border-box;
    place-items: center;

}

@media screen and (max-width: 600px) {
    .stats {
        grid-template-columns: 1fr;
    }

}

.statCol {
    max-width: max-content;
}


.statCol>div {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.statCol div {
    margin: .5rem .25rem;
}

.statCol>div>img {
    /* object-position: center;
    object-fit: contain;
    height: 2rem;
    width: 2rem; */
    margin: .5rem .25rem;
    display: block;
}