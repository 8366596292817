/* Styles for .bigblock
.IncomeStyles .bigblock {
    display: flex;
    align-items: center;
    width: 100%;
    background-color: var(--gray);
    padding: 3vh 3vw;
    border-radius: 25px;
    margin: 2vh 0;
    flex-direction: column;
}

.IncomeStyles .bigblock .leftblock {
    width: 100%;
}

.IncomeStyles .bigblock .leftblock h5 {
    font-weight: 600;
    font-size: 1.1rem;
}

.IncomeStyles .bigblock .legend {
    margin: 1vh 0;
}

.IncomeStyles .bigblock .nomt {
    margin-top: 0;
}

.IncomeStyles .sixblock {
    margin: 2vh 0;
    display: flex;
    align-items: center;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.IncomeStyles .sixblock .sixth {
    padding: 3vh 2vw;
    width: 48%;
    text-align: center;
    background-color: var(--gray);
    margin: 0.5vh 0;
    border-radius: 20px;
}

.IncomeStyles .sixblock .sixth span {
    font-weight: 600;
    margin: 1vh 0;
}

.IncomeStyles .homeVal {
    text-align: center;
}

.IncomeStyles .homeVal .title {
    font-size: 1.1rem;
    font-weight: 600;
    width: 60%;
    padding: 1vh 2vw;
    margin: 1vh auto;
    border-radius: 20px;
}

.IncomeStyles .homeVal .line {
    display: flex;
    justify-content: space-between;
    margin: 1vh 0;
}

.IncomeStyles .homeVal .line .third {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    margin: 1vh 1vw;
    width: 31%;
    text-align: center;
    border-radius: 20px;
}

.IncomeStyles .homeVal .line .third span {
    font-weight: 600;
    margin: 1vh 0;
}

.IncomeStyles .annual {
    text-align: center;
}

.IncomeStyles .annual .title {
    font-size: 1.1rem;
    font-weight: 600;
    width: 90%;
    padding: 1vh 2vw;
    margin: 1vh auto;
    border-radius: 20px;
}

.IncomeStyles .annual .line {
    display: flex;
    justify-content: space-between;
    margin: 1vh 0;
}

.IncomeStyles .annual .line .two {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    margin: 1vh 1%;
    width: 48%;
    text-align: center;
    border-radius: 20px;
}

.IncomeStyles .annual .line .two span {
    font-weight: 600;
    margin: 1vh 0;
} */

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
}



.section1 {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: .5rem;
}

@media screen and (max-width: 768px) {
    .section1 {
        grid-template-columns: repeat(2, 1fr);
    }

}

.gridCol2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: .5rem;
}

div.section1>div,
.paddinger {
    padding: 2.5rem 0;
}

h2 {
    font-size: 1.5rem;
    font-weight: 700;

}