/* .div {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.group1 {
    width: 100%;
}

.group1 .line {
    display: flex;
    justify-content: space-between;
}

.group1 .line .boxes {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    margin: 1vh 1vw;
    width: 31%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
}

.group1 .line .boxes p {
    margin: 0.5vh 0;
}

.group1 .line .boxes span {
    font-size: 1.5rem;
    display: block;
    font-weight: 600;
    margin: 1vh 0;
}

.bottomLine {
    background-color: var(--gray);
    height: auto;
    padding: 2.5vh 2vw;
    width: 100%;
    border-radius: 20px;
    margin: 1vh 0;
}

.bottomLine p {
    font-weight: 600;
    margin: 1vh 0;
}

.bottomLine span {
    display: block;
    margin: 1vh 0;
    color: var(--faded);
    text-decoration: underline;
}

.bottomLine span svg {
    height: 1rem;
    margin: 0 0.5vw;
}

.group3 {
    width: 100%;
    padding: 2vh 1vw;
}

.` {
    background-color: var(--gray);
    width: 100%;
    margin: 2vh 0;
    padding: 2vh 2vw;
    border-radius: 20px;
}

.subgroup h1 {
    text-align: center;
    font-size: 1.2rem;
}

.subgroup .line {
    margin: 0.5vh auto;
    text-align: center;
}

.subgroup .line a {
    color: var(--faded);
    text-decoration: underline;
}

.subgroup .line a svg {
    height: 1rem;
    margin: 0 0.5vw;
}

@media screen and (min-width: 1024px) {
    .div {
        display: flex;
    }

    .group1 .line {
        width: 60%;
    }

    .subgroup {
        padding: 2vh 1vw;
        width: 40%;
        max-height: 25vh;
        overflow-y: scroll;
    }
} */

.sectionContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
}

.section1Grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 1rem;
    min-width: 50%;
}

.groupList {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    gap: .25rem;
    max-height: 10rem;
    overflow-y: auto;
    /* flex-grow: 0; */
}

.demographics {
    box-sizing: border-box;
    width: 100%;
    border-radius: 1rem;
    background-color: var(--gray);
    text-align: left;
    padding: 1rem 2rem;
    grid-column-start: span 3;
}

.demographics>p {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
}

.demographics p {
    text-decoration: underline;
}
.overlayContent {
    /* background-color: #fff; Adjust as needed */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
  }
  

.overlay{
    background: black;
    color: white;
    opacity: 0.8;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Add a subtle box shadow */
}

.groupList>a,
.demographics>a {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: flex-start;
    gap: .5rem;
    height: max-content;
    margin: .25rem 0;
}

.groupList>a>svg,
.demographics>a>svg {
    height: auto;
    width: 1rem;
    fill: var(--grayBorder);
}

.groupList>a>p,
.demographics>p {
    font-size: 1rem;
    margin: 0;
    /* color: white; */
    text-decoration: underline;
}

.group3 {
    box-sizing: border-box;
    width: 100%;
    padding: 1rem 2rem;
}

.group3>h4 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: .5rem 0;
}

.group3>p {
    font-size: 1rem;
    margin: .5rem 0;
    text-align: justify;
}